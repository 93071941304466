import { defineStore } from 'pinia'

export const useSettingsStore = defineStore('settings', {
  state: () => ({
    muted: true,
    isShowSharePopup: false,
  }),
  actions: {
    toggleMuted() {
      this.muted = !this.muted
    },
    setMuted(payload) {
      this.muted = payload
    },
    toggleSharePopup() {
      console.log('state.isShowSharePopup : ' + !this.isShowSharePopup)
      this.isShowSharePopup = !this.isShowSharePopup
    },
  },
})
